import { AfterViewInit, Component, OnInit, ViewEncapsulation } from "@angular/core";
import { FederadosService } from "../federados/services/federados.service";
import { Router } from '@angular/router';
import { AlertService } from "src/app/services/alert.service";
import { GenericService } from "src/app/services/generic.service";
import { LoaderService } from "src/app/services/loading-service";
import { environment } from "src/environments/environment";
import Swal from "sweetalert2";
import { HttpErrorResponse } from "@angular/common/http";
declare var $: any;

@Component({
  selector: "app-eventos-historicos",
  templateUrl: "./eventos-historicos.component.html",
  styleUrls: ["./eventos-historicos.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EventosHistoricosComponent implements OnInit, AfterViewInit {
  public events: any = [];
  public events2: any[] = [];
  

  constructor(
    private router: Router, 
    private api: FederadosService, 
    private alertService: AlertService, 
    private genericService: GenericService,
    private loadingService: LoaderService) {}

  ngOnInit() {
    this.getEventosHistoricos();
  }

  ngAfterViewInit() {
    // this.destroyDataTable();
    if(this.events2.length > 0){
      this.initializeDataTable();
    }
  }

  getEventosHistoricos() {

    this.api.get("/getAllEventosHistoricos")
      .subscribe((res) => {
        let date 

        if(res.hay_eventos){
          this.events = res.eventos;
          this.events2 = this.events.map((arr)=>{
            const newArr = {...arr};
            // console.log(newArr.fecha_inicio_evento)
            var dateObject = new Date(arr.fecha_inicio_evento);
            
            newArr.fecha_inicio_evento=dateObject.toISOString().slice(0, 10)
            return newArr;
          })         
          
          
        }
        // else{
        //   this.initializeDataTable();
        // }
        this.initializeDataTable();

        
      },
      (err) => {
        console.log(err);
      }
    );
  }

  editEvent(item: { _id: string }) {
		this.router.navigate(['/pages/evento', item._id])
	}

  importEventosHistoricos() {
    let id: any = document.getElementById("file");
    id.click();
  }

  fileChange(evt: any) {
    let file: any = evt?.target?.files[0];
    if (file && file?.type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {
      this.alertService.errorAlert("Oops!", "Se espera un archivo .xlsx");
    } else {
      this.cargarMasivo(file);
    }
  }

  cargarMasivo(file: any): void {
    this.loadingService.show();
    const formData = new FormData();
    // console.log(file);

    formData.append('importFile', file);
    this.genericService.sendPostRequest(environment.importEventos, formData).subscribe({
      next: (r: any) => {
        console.log('Response:', r); // Aquí se imprime el resultado del backend en la consola
        this.alertService.successAlert("Bien!", "Archivo cargado correctamente");
        if(this.events2.length > 0){
          // this.initializeDataTable();
          this.getEventosHistoricos();
        }
        else {
          location.reload();
        }
        
        this.loadingService.hide();
      },
      error: (error) => {
        console.error('Error:', error.error); // Aquí se imprime cualquier error en la consola
        if(error.error.error){
          this.loadingService.hide();

          const estadosInexistentes = error.error.estadosInexistentes.join(', ');
          const unionesInexistentes = error.error.unionesInexistentes.join(', ');

          if(error.error.estadosInexistentes.length > 0){
            Swal.fire({
              //position: "top-end",
              icon: "error",
              title: "Error al importar Detalles Históricos",
              // text: `No existen los estados: ${estadosInexistentes}`,
              showConfirmButton: false,
              html: `<p style="text-align: center; font-size: 16px;"> No existen los estados: <strong>${estadosInexistentes}</strong></p>`,
              timer: 6000,
            }).then(() => {
              location.reload();
            });
          }
          
          if(error.error.unionesInexistentes.length > 0){
            Swal.fire({
              //position: "top-end",
              icon: "error",
              title: "Error al importar Detalles Históricos",
              // text: `No existen las uniones: ${unionesInexistentes}`,
              showConfirmButton: false,
              html: `<p style="text-align: center; font-size: 16px;"> No existen los estados: <strong>${unionesInexistentes}</strong></p>`,
              timer: 6000,
            }).then(() => {
              location.reload();
            });
            
          }
          
        }
      },
      complete: () => {
        console.log('File Uploaded:', file); // Aquí se imprime el archivo subido en la consola
      }
    });

    // this.genericService.sendPostRequest(environment.importEventos, formData).subscribe((r: any) => {
    //   console.log(r);
    //   this.alertService.successAlert("Bien!", "Archivo cargado correctamente");
    //   if(this.events2.length > 0){
    //     // this.initializeDataTable();
    //     this.getEventosHistoricos();
    //   }
    //   else {
    //     location.reload();
    //   }
      
    //   this.loadingService.hide();
    // }, (error: HttpErrorResponse) => {
    //   this.loadingService.hide();
    // });
  }

  // Destruir la tabla DataTable existente, si hay una
  destroyDataTable() {
    const dataTable = $("#dt").DataTable();
    if (dataTable) {
      dataTable.destroy();
    }
  }

  initializeDataTable() {
    console.log(this.events2);
      
      
    setTimeout(function () {
      $(function () {
        // // $("#dt").DataTable({
        // //   bDestroy: true,
        // //   retrieve: true,
        // //   bInfo: true,
        // //   bProcessing: true,
        // //   bDeferRender: true,
        // //   iDisplayLength: 10,
        // //   sPaginationType: "full_numbers",
        // //   sDom: '<"top"i> T<"clear">lfrtip',
        // //   sPageButtonActive: "paginate_active",
        // //   sPageButtonStaticDisabled: "paginate_button",
        // //   scrollY: 400,
        // //   scrollX: true,
        // //   scrollCollapse: true,
        // //   stateSave: true,
        // //   searching: true, // Habilitar búsqueda en todas las columnas
        // //   stateSaveCallback: function (settings, data) {
        // //     localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data));
        // //   },
        // //   stateLoadCallback: function (settings) {
        // //     return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance));
        // //   },
        // //   language: {
        // //     emptyTable: "No hay eventos disponibles",
        // //     // Otras opciones de lenguaje...
        // //   },
        // //   drawCallback: function (settings) {
        // //     if (settings.fnRecordsDisplay() === 0) {
        // //       // Si no hay registros, mostrar el mensaje personalizado
        // //       $("#dt").html(`<div class="no-elements-message">No hay eventos disponibles</div>`);
        // //     }
        // //   },
        // // })
        $("#dt").DataTable({
          bDestroy: true,
          retrieve: true,
          bInfo: true,
          bProcessing: true,
          bDeferRender: true,
          iDisplayLength: 10,
          sPaginationType: "full_numbers",
          sDom: '<"top"i> T<"clear">lfrtip',
          sPageButtonActive: "paginate_active",
          sPageButtonStaticDisabled: "paginate_button",
          scrollY: 400,
          scrollX: true,
          scrollCollapse: true,
          stateSave: true,
          stateSaveCallback: function (settings, data) {
            localStorage.setItem('DataTables_' + settings.sInstance, JSON.stringify(data));
          },
          stateLoadCallback: function (settings) {
            return JSON.parse(localStorage.getItem('DataTables_' + settings.sInstance));
          },
          language: {
            emptyTable: "No hay eventos disponibles",
            // Otras opciones de lenguaje...
          },
          drawCallback: function (settings) {
            if (settings.aiDisplay.length === 0) {
              // Si no hay registros en la búsqueda actual, mostrar el mensaje personalizado
              // $("#dt").html(`<div class="no-elements-message">No hay eventos disponibles</div>`);
              $("#no-events-message").show();
            } else {
                // Si hay registros en la búsqueda actual, eliminar el mensaje personalizado
                // $("#dt .no-elements-message").remove();
                $("#no-events-message").hide();
                $('#dt').DataTable().destroy();
                this.initializeDataTable(); // Vuelve a inicializar el DataTable
            }
          },  
          
        });
        
        // if (eventoRes?.hay_eventos && eventoRes.eventos.length > 50) {
        //   // Si hay eventos y la cantidad es mayor que 50, crear DataTable
        //   $("#dt").DataTable(dtOptions);
        // } else {
        //   // Mostrar mensaje si no hay eventos o la cantidad es menor o igual a 50
        //   // $('#dt').DataTable().draw().destroy();
        //   $("#dt").DataTable(dtOptions);
        // }
      });
    }, 1000);

    // Agregar inputs de búsqueda en el encabezado de cada columna
    $('#dt thead tr').clone(true).appendTo('#dt thead');
    $('#dt thead tr:eq(1) th').each(function (i) {
        var title = $(this).text();
        $(this).html('<input type="text" class="input-busqueda" placeholder="Buscar ' + title + '" />');

        $('input', this).on('keyup change', function () {
            if($('#dt').DataTable().column(i).search() !== this.value) {
              $('#dt').DataTable().column(i).search(this.value).draw();
            }
        });
    });
    
    $('#dt').DataTable().draw().destroy();
  }
  
  
}
