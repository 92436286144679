
/*
 *Item de header.
 */
export class HeaderItem {
  /** Generate Item */
  constructor(
    public id: number,
    public name: string,
    public link: string,
    public active: boolean = false,
    public action: Function,
    public icon: string = "",
    public isBuild: boolean = true,
  ) { }


  static staticPublicList(user: any = null): HeaderItem[] {
    let items: HeaderItem[] = [];
    items.push(
      new HeaderItem(1, "Inicio",  "/pages/estadisticas", false,null,"home",)
    );

    items.push(
      new HeaderItem(1, "Uniones", "/pages/union/tabla", false, () => {
        //window.open("/pages/union/tabla","_self");
      },"sitemap")
    );

    items.push(
      new HeaderItem(1, "Asociaciones", "/pages/asociacion/tabla", false, null, "landmark")
    );

    items.push(
      new HeaderItem(1, "Equipos", "/pages/equipos/tabla", false, null, "shield-alt")
    );

    items.push(
      new HeaderItem(1, "Federados", "/pages/federados/tabla", false,null,"user-shield")
    );


    items.push(
      new HeaderItem(1, "Recintos", "/pages/recintos", false, null, "drum-steelpan")
    );

    items.push(
      new HeaderItem(1, "Cuotas", "/pages/wip", false, () => {
        //window.open("/pages/union/tabla","_self");
      },"hand-holding-usd", false)
    );

    items.push(
      new HeaderItem(1, "Eventos", "/pages/tablaEvento", false, () => {
        //window.open("/pages/union/tabla","_self");
      },"calendar-alt")
    );
    
    items.push(
      new HeaderItem(1, "Resultados", "/pages/eventos-historicos", false, () => {
        //window.open("/pages/union/tabla","_self");
      },"trophy")
    );

    // items.push(
    //   new HeaderItem(1, "Resultados", "/pages/wip", false, () => {
    //     //window.open("/pages/union/tabla","_self");
    //   },"trophy",)
    // );

    // items.push(
    //   new HeaderItem(1, "Estadísticas", "/pages/estadisticas", false,null,"list",)
    // );



    items.push(
      new HeaderItem(1, "Usuarios", "/pages/users", false, null, "users")
    );

    items.push(
      new HeaderItem(1, "Configuraciones", "/pages/wip", false, null, "cog", false)
    );

    items.push(
      new HeaderItem(10, "Salir", "/pages/users", false, null, "power-off")
    );

    /* items.push(
      new HeaderItem(1, "Salir", "/login", false, null, "power-off")
    ); */

    return items;
  }

 
}

