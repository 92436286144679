import { Component, EmbeddedViewRef, OnInit, Inject, PLATFORM_ID,ChangeDetectorRef } from "@angular/core";
import { FederadosService } from "../federados/services/federados.service";
import { environment } from "../../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { defineCustomElements } from '@ionic/pwa-elements/loader';
import Swal from "sweetalert2";
import { LoaderService } from './../../services/loading-service';
import { GenericService } from "./../../services/generic.service";
import { HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { AlertService } from "./../../services/alert.service";
@Component({
  selector: 'app-detalles-kiosko',
  templateUrl: './detalles-kiosko.component.html',
  styleUrls: ['./detalles-kiosko.component.scss'],
})
export class DetallesKioskoComponent implements OnInit {
  public perfil:any;
  public repass: any=null
  public codigo:any

  // ls: any = JSON.parse(localStorage.getItem('userSessionFederacion'));
  // public userPerfil: String = this.ls.perfiles[0];
  public isCredencialBtnDisabled: Boolean = false;
  public anos:string[]=[];
  public meses:any[]=[];
  public dias:any[]=[];
  public b64Image: any = "";
  FederadosList: {};
  public img2: any = environment.urlServerimg;
  hideModal: boolean;
  url: string;
  urlupdate: string;
  filename2: any;
  file2: "";
 
 
  asociacionesList: string[];
  
  equipoList: string[] = [];
  equipoData: any[];
  
  url1: string;
  url2: string;
  img: any;
  ids: any;
  aso: any;
  equi: any;

  public data: any = null;

  public dataRender: any = [
    {
      fieldset: [
        {
          id: "inp-nombre",
          formControl: "nombre",
          label: "Nombre",
          placeholder: "NOMBRE",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        }, {
          id: "inp-ap_paterno",
          formControl: "ap_paterno",
          label: "Apellido Paterno",
          placeholder: "APELLIDO PATERNO",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        }, {
          id: "inp-ap_materno",
          formControl: "ap_materno",
          label: "Apellido Materno",
          placeholder: "APELLIDO MATERNO",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        }, {
          id: "inp-perfil",
          formControl: "perfiles",
          label: "Perfil",
          placeholder: "Perfil",
          required: false,
          defaultValue: "deportista",
          type: "readOnly",
          withError: false
        },
       
      ],
      legend: "NOMBRE COMPLETO"
    },
    {
      fieldset: [
        {
          id: "inp-curp",
          formControl: "curp",
          label: "Curp",
          placeholder: "CURP",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        },
        {
          id: "inp-fecha_nac",
          formControl: "fecha_nac",
          label: "Fecha de Nacimiento",
          placeholder: "0000-00-00",
          required: true,
          defaultValue: "1984-01-01",
          type: "date",
          withError: false
        },  
        {
          id: "inp-sexo",
          formControl: "sexo",
          label: "Sexo",
          placeholder: "SEXO",
          required: true,
          defaultValue: "H",
          type: "disabled",
          withError: false,
          dato: ['H', 'M']
        }, 
        {
          id: "inp-pais_origen",
          formControl: "pais_origen",
          label: "País de Union",
          placeholder: "PAIS UNION",
          required: true,
          defaultValue: "MEXICO",
          type: "disabled",
          withError: false,
          dato: ['MEXICO', 'USA']
        },
        {
          id: "inp-estado_origen",
          formControl: "estado_origen",
          label: "Union",
          placeholder: "UNIÓN",
          required: true,
          defaultValue: "",
          type: "disabled",
          withError: false,
          dato: []
        },
        {
          id: "inp-asociacion",
          formControl: "asociacion",
          label: "Asociación",
          placeholder: "ASOCIACIÓN",
          required: true,
          defaultValue: "",
          type: "disabled",
          withError: false,
          dato: [],
        },
        {
          id: "inp-tipo_federado",
          formControl: "tipo_federado",
          label: "Tipo Federado",
          placeholder: "Tipo Federado",
          required: true,
          defaultValue: "Charro",
          type: "disabled",
          withError: false,
          dato: ["Charro","ESCARAMUZA"]
        },
        {
          id: "inp-folio",
          formControl: "no_registroFMCH",
          label: "Folio FMCH",
          placeholder: "#####",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        },
        {
          id: "inp-equipo",
          formControl: "equipo",
          label: "Equipo",
          placeholder: "EQUIPO",
          required: true,
          defaultValue: "",
          type: "disabled",
          withError: false,
          dato: []
        },
       

      ],

      legend: "FECHA Y LUGAR DE NACIMIENTO"
    },
    {
      fieldset: [
        {
          id: "inp-email",
          formControl: "email",
          label: "Email",
          placeholder: "CORREO ELECTRÓNICO",
          required: true,
          defaultValue: "",
          type: "text",
          withError: false
        },
        {
          id: "inp-password",
          formControl: "password",
          label: "Contraseña",
          placeholder: "CONTRASEÑA",
          required: true,
          defaultValue: "",
          type: "password",
          withError: false
        },
        {
          id: "inp-clave_pais",
          formControl: "clave_pais",
          label: "CÓDIGO DE PAÍS +52 (MX) ó +1 (USA)",
          placeholder: "CODIGO DE PAIS",
          required: true,
          defaultValue: "+52",
          type: "readOnly",
          withError: false
        },
        {
          id: "inp-cel",
          formControl: "cel",
          label: "Cel",
          placeholder: "CELULAR (10 DÍGITOS)",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        },
        {
          id: "inp-domicilio_pais",
          formControl: "domicilio_pais",
          label: "País Residencia",
          placeholder: "DOMICILIO PAIS",
          required: true,
          defaultValue: "MEXICO",
          type: "disabled",
          withError: false,
          dato: ['Mexico', 'USA']
        }, {
          id: "inp-domicilio_cp",
          formControl: "domicilio_cp",
          label: "CP/ZIP CODE de Residencia",
          placeholder: "CÓDIGO POSTAL / ZIP CODE",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        }, {
          id: "inp-colonia",
          formControl: "domicilio_colonia",
          label: "COLONIA",
          placeholder: "COLONIA",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        },

        {
          id: "inp-calle",
          formControl: "domicilio_calle",
          label: "CALLE Y NUMERO",
          placeholder: "CALLE Y NUMERO",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        },

        {
          id: "inp-entidad",
          formControl: "domicilio_entidad",
          label: "ENTIDAD DE NACIMIENTO",
          placeholder: "ENTIDAD DE NACIMIENTO",
          required: true,
          defaultValue: "",
          type: "disabled",
          withError: false,
          dato: [
            'AGUASCALIENTES',
            'BAJA CALIFORNIA',
            'BAJA CALIFORNIA NORTE',
            'BAJA CALIFORNIA SUR',
            'CAMPECHE',
            'COAHUILA',
            'COLIMA',
            'CHIAPAS',
            'CHIHUAHUA',
            'CIUDAD DE MEXICO',
            'DISTRITO FEDERAL',
            'DURANGO',
            'GUANAJUATO',
            'GUERRERO',
            'HIDALGO',
            'JALISCO',
            'MEXICO',
            'MICHOACAN',
            'MORELOS',
            'NAYARIT',
            'NUEVO LEON',
            'OAXACA',
            'PUEBLA',
            'QUERETARO',
            'QUINTANA ROO',
            'SAN LUIS POTOSI',
            'SINALOA',
            'SONORA',
            'TABASCO',
            'TAMAULIPAS',
            'TLAXCALA',
            'VERACRUZ',
            'YUCATAN',
            'ZACATECAS',
            'NACIDO EN EL EXTRANJERO',
          ],
        },
      ], legend: "DOMICILIO ACTUAL"
    },

    {
      fieldset: [
        {
          id: "inp-nombre_completo",
          formControl: "nombre_tutor",
          label: "NOMBRE COMPLETO TUTOR",
          placeholder: "NOMBRE COMPLETO",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        },

        {
          id: "inp-parentesco",
          formControl: "parentesco_tutor",
          label: "Parentesco",
          placeholder: "PARENTESCO",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        },
        {
          id: "inp-cel_emergencias",
          formControl: "cel_emergencias",
          label: "Cel para emergencias",
          placeholder: "CEL PARA EMERGENCIAS",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        },
        {
          id: "inp-curp_tutor",
          formControl: "curp_tutor",
          label: "CURP Benef o Tutor",
          placeholder: "CURP",
          required: true,
          defaultValue: "",
          type: "readOnly",
          withError: false
        }, 
        {
          id: "inp-escolaridad",
          formControl: "escolaridad",
          label: "Escolaridad",
          placeholder: "ESCOLARIDAD",
          required: true,
          defaultValue: "",
          type: "disabled",
          withError: false,
          dato: [
            'Primaria',
            'Secundaria',
            "Media Superior",
            "Licenciatura",
            "Otro",
          ]
        },
      
      ], legend: "DATOS DE CONTACTO"
    },
    
    //4
    {
      fieldset: [
        {
          id: "inp-facebook",
          formControl: "facebook",
          label: "Facebook",
          placeholder: "@FACEBOOK",
          required: true,
          defaultValue: "@FACEBOOK",
          type: "readOnly",
          withError: false
        },
       
        {
          id: "inp-instagram",
          formControl: "instagram",
          label: "instagram",
          placeholder: "@INSTAGRAM",
          required: true,
          defaultValue: "@INSTAGRAM",
          type: "readOnly",
          withError: false
        },
       
        {
          id: "inp-twitter",
          formControl: "twitter",
          label: "twitter",
          placeholder: "@TIKTOK",
          required: true,
          defaultValue: "@TIKTOK",
          type: "readOnly",
          withError: false
        },

      ], legend: "REDES SOCIALES  "
    },
   //5

   { 
    fieldset:[
      {      
      id: "inp-status_pago",
      formControl: "status_pago",
      label: "Estatus Pago Federación",
      placeholder: "Estatus pago",
      required: true,
      defaultValue: "Expirado",
      type: "disabled",
      withError: false,
      dato: ["Vigente", "Expirado"]
    },
    {
      id: "inp-seguro_status",
      formControl: "seguro_status",
      label: "Seguro Estatus",
      placeholder: "Seguro Estatus",
      required: true,
      defaultValue: "Expirado",
      type: "disabled",
      withError: false,
      dato: ["Vigente", "Expirado"]
    },
    {
      id: "inp-comprobante_pago_url",
      formControl: "comprobante_pago_url",
      label: "Comprobante",
      placeholder: "Comprobante...",
      required: true,
      defaultValue: "pendiente",
      type: "pago",
      withError: false,
      
    },
  ], legend: "DATOS DE AFILICION GENERADOS POR SISTEMAS"

   },
  //6
    {
      id: "inp-categoria_federado",
      formControl: "categoria_federado",
      label: "Categoría Federado",
      placeholder: "Categoría",
      required: true,
      defaultValue: "pendiente",
      type: "disabled",
      withError: false,
      dato: [],
    },
    //7
    {
      id: "inp-cuota_costo",
      formControl: "cuota_costo",
      label: "Cuota Federación",
      placeholder: "200",
      required: true,
      defaultValue: "pendiente",
      type: "readOnly",
      withError: false
    },
    //8
    {
      id: "inp-fecha_pago_cuota_federacion",
      formControl: "fecha_pago_cuota_federacion",
      label: "Cuota Fecha Pago",
      placeholder: "AAAA/MM/DD",
      required: true,
      defaultValue: "1981-01-01",
      type: "date",
      withError: false
    },

  //9
    {
      id: "inp-seguro_poliza",
      formControl: "seguro_poliza",
      label: "Seguro póliza",
      placeholder: "555555555",
      required: true,
      defaultValue: "pendiente",
      type: "disabled",
      withError: false,
      dato: []
    },

    //10
    {
      id: "inp-seguro_costo",
      formControl: "seguro_costo",
      label: "Seguro Costo",
      placeholder: "200",
      required: true,
      defaultValue: "pendiente",
      type: "readOnly",
      withError: false
    },

    //11
    {
      id: "inp-seguro_fecha_pago",
      formControl: "seguro_fecha_pago",
      label: "Seguro Fecha Pago",
      placeholder: "AAAA/MM/DD",
      required: true,
      defaultValue: "1981-01-01",
      type: "date",
      withError: false
    },
    //12
   
    {
      derecha: [
        {
          id: "inp-foto",
          formControl: "foto",
          label: "foto",
          placeholder: "foto",
          required: true,
          defaultValue: "pendiente",
          type: "readOnly",
          withError: false,
          noVisual: true
        },
        
       
        
      ]
    },
    
  ];

  public nombre_completo: any = "";



  public fed: any = null;

  public id: any = null;

  public datosFederadoByCurp: any = {};
  public federadoCurpValid: boolean;
  constructor(
    private alertService: AlertService,
    public api: FederadosService,
    private _Activatedroute: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private loadingService: LoaderService,
    private genericService: GenericService,
    public router: Router,
  ) { }

  ngOnInit() {

   
    this._Activatedroute.params.subscribe(
      (params: { Params }) => {
        this.perfil=params["perfil"]
        this.buscarFederado(params["id"])
                this.ids = params["id"];
        if (this.ids) {
          this.id = this.ids;
          
        }
        this.api.get(`/getIneByFederadoId/${this.ids}`).subscribe((res) => {

          console.log(res[0].rutaFotoDelantera)
        
          this.b64Image=this.img2+res[0].rutaFotoDelantera.replace('uploads/', '');
       
         console.log('----',this.b64Image)
       });
    
    

        if (!this.ids) {
          this.data = { id: this.ids };
          this.ids = this.data.id;
          
        }
        //console.log(this.ids);
        if (this.id) {
          this.url = "/getFederadoById/" + this.ids;
          this.api.get(this.url).subscribe((res) => {
            console.log("federados", res);
            this.fed = res[0];

            this.nombre_completo = res[0].nombre_completo;

            res.forEach((res) => {
              // console.log(res);

              this.ids = res._id
              this.urlupdate = "/updateFederado/" + this.ids;
              this.img = res.foto
              if (res.foto) {
                this.img = environment.urlServerimg + res.foto;
                //console.log(this.img);
              }

              let objForm: any = {};
              this.dataRender.forEach(itm => {
                //if(itm.formControl.includes(itm[])){
                if (!itm.fieldset && !itm.derecha) {
                  objForm[itm.formControl] = res[itm.formControl];
                  itm.defaultValue = res[itm.formControl];

                  if (itm.formControl == "foto") {
                    console.log("FOOOOOOTOOOOO");
                    console.log(itm);

                    this.dataRender[12].derecha[0]["foto"] = res[itm.formControl];

                  }
                } else if (itm.derecha) {
                  itm.derecha.forEach(itm => {
                    objForm[itm.formControl] = res[itm.formControl];
                    itm.defaultValue = res[itm.formControl];
                    if (itm.formControl == "foto") {
                     // this.dataRender[12].derecha[0]["foto"] = res[itm.formControl];
                      this.loadingService.hide();
                    }
                  });

                } else {
                  itm.fieldset.forEach(itm => {
                    objForm[itm.formControl] = res[itm.formControl];
                    itm.defaultValue = res[itm.formControl];
                    if (itm.formControl == "foto") {
                      this.dataRender[12].derecha[0]["foto"] = res[itm.formControl];
                      
                    }
                  });

                }
                //}
              });
              // console.log(this.dataRender[12].derecha[0]);
              this.dataRender[2].fieldset[0].defaultValue="";
              this.dataRender[2].fieldset[1].defaultValue="";
              //this.generalForm.setValue(objForm);
            });
          });
          
        } else {
          this.dataRender[12].derecha[0]["foto"] = "";
          this.loadingService.hide();
        }
      }
      


    );
   
  }

  buscarFederado(dato) {
    this.api.get(`/getFederadoById/${dato}`).subscribe((res) => {


      this.codigo=res[0].password

  ////// FECHA NACIMIENTO    
      let fecha = res[0].fecha_nac;
      let partes = fecha.split("-");
      let mes = partes[2];
      let dia = partes[1];
      let ano = partes[0];
      let mesValue
      mesValue=document.getElementById('mes')
      mesValue.value=mes
      let diaValue
      diaValue=document.getElementById('dia')
      diaValue.value=dia
      let anoValue
      anoValue=document.getElementById('ano')
      anoValue.value=ano



//////SEXO
      
      if(res[0].sexo=='h'){
        let hombre
        this.dataRender[1].fieldset[2].defaultValue='H'
        hombre=document.getElementById('HOMBRE')
      hombre.checked=true
      let mujer
        mujer=document.getElementById('MUJER')
      mujer.checked=false
      }else{
        this.dataRender[1].fieldset[2].defaultValue='M'
        let hombre
        hombre=document.getElementById('HOMBRE')
      hombre.checked=false
      let mujer
        mujer=document.getElementById('MUJER')
      mujer.checked=true
      }
//////// PAIS ORIGEN 
console.log(this.dataRender[1].fieldset[3].defaultValue)
      if(res[0].pais_origen[0]=='mexico'){
        this.dataRender[1].fieldset[3].defaultValue='mexico'
        let pais_origen_mexico
        pais_origen_mexico=document.getElementById('pais_origen_mexico')
        pais_origen_mexico.checked=true
        let pais_origen_usa
        pais_origen_usa=document.getElementById('MUJER')
        pais_origen_usa=false
      }else{
        this.dataRender[1].fieldset[3].defaultValue='usa'
        let pais_origen_mexico
        pais_origen_mexico=document.getElementById('pais_origen_mexico')
        pais_origen_mexico.checked=false
        let pais_origen_usa
        pais_origen_usa=document.getElementById('MUJER')
        pais_origen_usa=true
      }

////////


/////
      this.dataRender[1].fieldset[1].defaultValue=ano+"-"+mes+"-"+dia
      
      //console.log( this.dataRender[12].derecha)
      this.dataRender[12].derecha[0].foto=this.img2+res[0].foto
      this.dataRender[12].derecha[0].fotob64=this.img2+res[0].foto
    console.log(this.dataRender[12].derecha[0].foto)
    let objForm: string ;
    this.dataRender[1] .fieldset.forEach(itm => {
      let objForm: any = {};
      
      objForm=res[0];
     
      if(itm.formControl!='no_registroFMCH'){
      itm.defaultValue=objForm[itm.formControl];}
    });

    
    this.dataRender[0] .fieldset.forEach(itm => {
      let objForm: any = {};
      objForm=res[0];
      //console.log(itm.formControl)
      if(itm.formControl!='perfiles'){
      itm.defaultValue=objForm[itm.formControl];}
    });

    this.dataRender[2] .fieldset.forEach(itm => {
      let objForm: any = {};
      objForm=res[0];
      itm.defaultValue=objForm[itm.formControl];
    });
    this.dataRender[3] .fieldset.forEach(itm => {
      let objForm: any = {};
      objForm=res[0];
      itm.defaultValue=objForm[itm.formControl];
    });
    this.dataRender[4] .fieldset.forEach(itm => {
      let objForm: any = {};
      objForm=res[0];
      itm.defaultValue=objForm[itm.formControl];
    });
    
    });

  
  }

  mostrarcontrasena(tipo){
    if(tipo==1){
    let input
    input=document.getElementById('password')
    let span
    span=document.getElementById('spass')
    if(input.type=='password'){
      input.type='readOnly'
      span.innerreadOnly="visibility"
     // console.log(span.innerreadOnly)
    }else{
      input.type='password'
      span.innerreadOnly="visibility_off"
    //  console.log(span.innerreadOnly)

    }
    }

    if(tipo==2){
      let input
      input=document.getElementById('repassword')
      let span
      span=document.getElementById('srepass')
      if(input.type=='password'){
        input.type='readOnly'
        span.innerreadOnly="visibility"
       // console.log(span.innerreadOnly)
      }else{
        input.type='password'
        span.innerreadOnly="visibility_off"
      //  console.log(span.innerreadOnly)
  
      }
      }

  }

  onImageChange2(e) {
    const files = e.target.files || e.dataTransfer.files;
    this.filename2 = "Selected file: " + files[0].name;
    this.file2 = files[0];
  }

  onImageChange(e) {
    //console.log(e.target.files)
    const files = e.target.files || e.dataTransfer.files;
    this.filename2 = "Selected file: " + files[0].name;
    this.file2 = files[0];
    //this.filename2 = "Selected file: " + files[0].name;
    this.dataRender[12].derecha[0].defaultValue = files[0];
    this.dataRender[12].derecha[0].foto = "";

    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      this.dataRender[12].derecha[0].fotob64 = reader.result;
    };

  }

  btn() {
    document.getElementById("hf-logo").click();
    let Credencial
    Credencial=document.getElementById('credencial')
    Credencial.classList.add('cover');
   
  }
  openModalCredOpt() {
    let INE
    INE=document.getElementById('INE')
    INE.classList.add('cover2');
    INE.classList.remove('remove');
    
    this.captureImage(true, true);
  }


  async captureImage(isCamera: boolean = true, frontCred: boolean) {
   // platformBrowserDynamic().bootstrapModule(AppModule).catch(err => console.log(err));
  
    // Call the element loader after the platform has been bootstrapped
    defineCustomElements(window);
    const image = await Camera.getPhoto({
      quality: 40,
      allowEditing: false,
      resultType: CameraResultType.Base64,
      saveToGallery: false,
      source: isCamera ? CameraSource.Camera : CameraSource.Photos
    });
    
    this.b64Image = 'data:image/jpeg;base64,' + image.base64String/*.slice(0, 1000)*/;
    let photoFileObj: any = {
      b64:  this.b64Image ,
      id: null, //usuario de nueva creacion
      extension: "png",
      table: "Credencial",
    };

   // console.log("image => ", image);
   // console.log("requestFile => ", photoFileObj);
    
    
  console.log(photoFileObj);

    //Endpoint para guardar solamente la foto tomada.
    this.api.uploadPhoto(photoFileObj.b64).subscribe((res: any) => {
      if(frontCred){
        localStorage.setItem('photoFileObjFront', res.rutaImagen);
      }
      // if(backCred){
      //   localStorage.setItem('photoFileObjBack', res.rutaImagen);
      // }
     })
    
    this.changeDetectorRef.detectChanges();
  }


  addFederados() {

   
    //console.log(this.validateInputs());
    let mes
      mes=document.getElementById('mes')
      
      let dia
      dia=document.getElementById('dia')
      
      let ano
      ano=document.getElementById('ano')
    

      this.dataRender[1].fieldset[1].defaultValue=ano.value+"-"+mes.value+"-"+dia.value
      //console.log(this.dataRender[1].fieldset[1].defaultValue)


    if(this.validateInputs()&&this.validarcontrasena()&&this.validarcheckbox()) {
      this.loadingService.show("Actualizando");
      let formData = new FormData();
      const headers = new HttpHeaders();
      headers.append("Content-Type", "multipart/form-data");
      headers.append("Accept", "application/json");
      //formData.append("email", this.generalForm.value.email);
      /*  formData.append("nombre", this.generalForm.value.nombre);
       formData.append("ap_paterno", this.generalForm.value.ap_paterno);
       formData.append("ap_materno", this.generalForm.value.ap_materno);
       formData.append("fecha_nac", this.generalForm.value.fecha_nac);
       formData.append("curp", this.generalForm.value.curp);
       formData.append("sexo", this.generalForm.value.sexo);
       formData.append("pais_origen", this.generalForm.value.pais_origen);
       formData.append("estado_origen", this.generalForm.value.estado_origen);
       formData.append("importFile", this.file2);
       formData.append("domicilio_pais", this.generalForm.value.domicilio_pais);
       formData.append("domicilio_cp", this.generalForm.value.domicilio_cp);
       formData.append("clave_pais", this.generalForm.value.clave_pais);
       formData.append("cel", this.generalForm.value.cel);
       formData.append("tipo_federado", this.generalForm.value.tipo_federado);
       formData.append(
         "categoria_federado",
         this.generalForm.value.categoria_federado
       );
       formData.append("seguro_costo", this.generalForm.value.seguro_costo);
       formData.append("seguro_status", this.generalForm.value.seguro_status);
       formData.append("seguro_poliza", this.generalForm.value.seguro_poliza);
       formData.append(
         "seguro_fecha_pago",
         this.generalForm.value.seguro_fecha_pago
       );
       formData.append("seguro_vigencia", this.generalForm.value);
       formData.append(
         "fecha_pago_cuota_federacion",
         this.generalForm.value.fecha_pago_cuota_federacion
       );
       formData.append("cuota_costo", this.generalForm.value.cuota_costo);
       formData.append(
         "comprobante_pago_url",
         this.generalForm.value.comprobante_pago_url
       );
       formData.append("vencimiento", this.generalForm.value.vencimiento);
       formData.append("status_pago", this.generalForm.value.status_pago);
       formData.append("asociacion", this.generalForm.value.asociacion);
       formData.append("equipo", this.generalForm.value.equipo);
       formData.append("perfil1", this.generalForm.value.perfiles);
    */
      //formData.append("perfil2", this.federadosForm.value.perfil2);
  
      let dataJson = {};
      this.dataRender.forEach(element => {
       
        if (!element.fieldset && !element.derecha ) {
          if (element.defaultValue == undefined) {
            element.defaultValue = "";
          }
          if (element.formControl == "no_registroFMCH") {

          }
       
          if (element.formControl == "foto") {
          } else {
            formData.append(element.formControl, String(element.defaultValue));
            dataJson[element.formControl] = element.defaultValue;
          }
  
        } else if (element.derecha) {
          element.derecha.forEach(itm => {
            if (itm.defaultValue == undefined) {
              itm.defaultValue = "";
            }
  
             if (element.formControl == "foto") {
            } else {
              formData.append(itm.formControl, String(itm.defaultValue));
              dataJson[itm.formControl] = itm.defaultValue;
            }
          });
        } else {
       //   console.log("datos",element.fieldset)
          element.fieldset.forEach(itm => {
            if (itm.defaultValue == undefined) {
              itm.defaultValue = "";
            }
  
            if (element.formControl == "foto") {
            } else {
              formData.append(itm.formControl, String(itm.defaultValue));
              dataJson[itm.formControl] = itm.defaultValue;
            }
            
            if(itm.formControl === 'password'){
              localStorage.setItem('pass', itm.defaultValue);
            }
  
          });
        }
  
      });
      formData.delete("foto");
      /* formData.append("importFile", this.dataRender[12].derecha[0].defaultValue); */
  
      if (this.dataRender[12].derecha[0].foto.length <= 0) {
        formData.append("importFile", this.dataRender[12].derecha[0].defaultValue);
        dataJson["importFile"] = this.dataRender[12].derecha[0].defaultValue;
      } else {
        formData.append("importFile", this.dataRender[12].derecha[0].foto);
        dataJson["importFile"] = this.dataRender[12].derecha[0].foto;
      }
      if(localStorage.getItem('photoFileObjFront') !== null){
        formData.append("photoFileRouteFront", localStorage.getItem('photoFileObjFront'));
      }
      // if(localStorage.getItem('photoFileObjBack') !== null){
      //   formData.append("photoFileRouteBack", localStorage.getItem('photoFileObjBack'));
      // }
  
      // console.log(localStorage.getItem('photoFileObjFront'));
    //  console.log(dataJson);
     // console.log(formData);
    // formData.append("perfiles", localStorage.getItem('perfil'));
     
      let url = this.ids ? this.urlupdate : "/createFederadoUser";
  
      let sus = this.api.update(url, formData, headers);
      if (!this.ids) {
        sus = this.api.post(url, formData);
      }
      sus.subscribe((res: any) => {
        this.loadingService.hide();
        this.router.navigate(["/login"]);
        
          this.codigo=res.federadoObj.password
          
        this.sendemail();   
      //  console.log("Response del createFed => ",res);

        const fedObj = {
          email: res.savedFederado.email,
          idEquipo: res.savedFederado.equipo[0]
        };
        localStorage.setItem('fedObj', JSON.stringify(fedObj));
        
        if (res.status === 200) {
          // this.clearForm();
          // this.showWareHoouse();
          // this.router.navigateByUrl('/usuario/u/warehouseCheck');
this.router.navigate(["/login"]);
          let d:any = {
            //position: "top-end",
            icon: "success",
            title: url === '/createFederadoUser' ? `${res.msg}` : `${res.msg}`,
            showConfirmButton: false,
            timer: 1500,
          };
          
          Swal.fire(d).then((result) => {
            // this.goTable();
          //  this.openModal();
          });
          
        }
        else{
          Swal.fire({
            //position: "top-end",
            icon: "error",
            title: url === '/createFederadoUser' ? `${res.msg}` : "No hemos podido actualizar el federado, intenta nuevamente",
            showConfirmButton: false,
            timer: 3000,
          }).then((result) => {
            // this.goTable(); 
          });
        }
      }
      , (error: HttpErrorResponse) => {
        console.error(error);
      });   
    
    }
  
  }
  validateInputs() {
    let isInputValid = true;
   // console.log(this.dataRender[1].fieldset[1].defaultValue)
    this.dataRender.forEach(element => {
      if(element.fieldset) {
        element.fieldset.forEach(e => {
          if(e.defaultValue == '') {
            isInputValid = false;
            Swal.fire({
              //position: "top-end",
              icon: "success",
              title: "Faltan Datos por en  " + e.label,
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              //this.clearForm();
            });
          };
        });
      }else if(element.derecha) {
        
        element.derecha.forEach(e => {
          if(e.defaultValue == '' && e.label !== 'Comprobante' ) {
            isInputValid = false;
            Swal.fire({
              //position: "top-end",
              icon: "success",
              title: "Faltan Datos por en  " + e.label,
              showConfirmButton: false,
              timer: 1500,
            }).then((result) => {
              //this.clearForm();
            });

          };
        });
      }else {
        if(element.defaultValue == '' && element.id !== 'inp-facebook' && element.id !== 'inp-instagram' && element.id !== 'inp-twitter') {
          isInputValid = false;
          Swal.fire({
            //position: "top-end",
            icon: "success",
            title: "Faltan Datos por en  " + element.label,
            showConfirmButton: false,
            timer: 1500,
          }).then((result) => {
            //this.clearForm();
          });
        };
      }
    });

    return isInputValid;
  }
  validarcontrasena(){
    let isValid=true
    if(this.repass!=this.dataRender[2].fieldset[1].defaultValue){
      isValid = false;
      Swal.fire({
        //position: "top-end",
        icon: "success",
        title: "la Contrasena no coiciden  ",
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        //this.clearForm();
      });
    }
    return isValid;
  }
  validarcheckbox(){

    let isValid=true
    let input
    input=document.getElementById('aviso')
    if(!input.checked){
      //console.log(input.checked)
      isValid = false;
      Swal.fire({
        //position: "top-end",
        icon: "success",
        title: "Tienes que Aceptar Aviso de Privacidad",
        showConfirmButton: false,
        timer: 1500,
      }).then((result) => {
        //this.clearForm();
      });
    }
   
    return isValid;
  }

  sendemail(){
   
    
    let request: any = {
      asunto: "Correo de Verificacion",
      from: "soporte@fmch.org.mx",
      name: localStorage.getItem('nombreC'),
      to: this.dataRender[2].fieldset[0].defaultValue,
      cuerpo: `<section>
  <div style="background-color: #006b89;
  text-align: center;padding: 8px;">
      <p style="color: #fff;margin: 0;font-size: 20px;">Federacion Mexicana de Charreria</p>
  </div>
  <div style="padding: 10px;border: 1px solid #c8c8c8;">
    <p style="color: #000;">Se le ha mandado un Correo de Verificacion con el siguiente codigo ${this.codigo} </p>
    
    <a href="https://fmch.org.mx/validacion/${this.ids}/${this.perfil}/"><button style="color: #fff;
      background-color: #006b89;
      font-size: 16px;
      padding: 8px;
      border-radius: 8px;
      box-shadow: 1px 1px 1px #123;
      margin-bottom: 20px;
      min-width: 200px;
      cursor: pointer;" >Validar Email</button></a>

    <p style="color: #000;">Da Click al Boton para entrar a validar tu correo</p>
    
  </div>
</section>`,
    };

    this.genericService.sendPostRequest('https://api.fmch.org.mx/api/validateEmail', request) .subscribe(
        (response: any) => {
          this.loadingService.hide();
          //window.history.back();
          //se hace un update de token del usuario
          //this.fcmService.updateTokenUser();
          //
          this.alertService.successAlert(
            'Correo Enviado a Validacion',
            'Por Favor Revisa tu Correo para validar tu Perfil'
          );})
  }
}


